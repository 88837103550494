<template>
  <div id="return-terms">
    <h1 class="terms__title">退換貨規則</h1>
    <div class="terms__title-divider" />

    <h4>
      我們致力於提供優質的布料產品和優良的服務，如果您對購買的產品有任何不滿意之處，我們將竭誠為您提供退換貨服務。請您仔細閱讀以下退換貨政策：
    </h4>

    <h3>一、退換貨申請</h3>
    <p>
      1. 申請期限：您可在收到產品後的7天內申請退換貨服務，超過此期限將不予受理。
    </p>
    <p>
      2.
      申請方式：請聯繫我們的客服團隊，提供訂單編號、產品照片及退換貨原因，我們將在收到您的申請後
      的2個工作日內回覆處理。
    </p>

    <h3>二、退貨條件</h3>
    <p>
      1.
      產品狀態：產品必須保持全新、未使用過，並保留原始包裝及所有附屬配件（如標籤、說明書等）。
    </p>
    <p>2. 不適用退貨的情況：</p>
    <p>- 已經剪裁或修改過的布料</p>
    <p>- 因個人原因導致的損壞（如污漬、破損等）</p>

    <h3>三、換貨條件</h3>
    <p>
      1.
      換貨範圍：若產品存在品質問題或發錯貨，我們將免費為您更換相同或等值的產品。換貨商品若無庫
      存，採退款方式處理。
    </p>
    <p>
      2.
      換貨流程：請您在收到產品後7天內聯繫客服申請換貨，並提供相關證明，我們將在核實後為您安排換
      貨事宜。
    </p>

    <h3>四、退款方式</h3>
    <p>
      1.
      退款處理：當我們收到並確認退貨產品符合退貨條件後，我們將在5個工作日內為您處理退款。
    </p>
    <p>
      2. 退款方式：退款將退回至您支付時使用的原支付方式（如信用卡、LINE
      PAY等）。
    </p>

    <h3>五、運費政策</h3>
    <p>1. 退換貨運費：</p>
    <p>- 若因品質問題或發錯貨而退換貨，運費由我們承擔。</p>
    <p>- 若因個人原因退貨，運費由買家自行承擔。</p>

    <h3>六、注意事項</h3>
    <p>
      1. 包裝要求：退換貨產品需妥善包裝，如有贈品等附件也請一併寄回。
    </p>
    <p>
      2.
      溫馨提示：請在收到產品時仔細檢查，並建議開箱時進行錄影，如發現任何問題，請立即與我們聯繫。
      若您在產品包裝內未見發票，屬正常情況，因為我們會在您確認產品無誤後才寄出發票。
    </p>
    <p>3. 如未取件遭退貨，再次寄出需補運費60元。蓄意未取件者，將加入黑名單。</p>

    <div class="terms__box">
      <h4>
        我們的目標是確保每一位顧客都能滿意我們的產品和服務，如果您有任何疑問或需要進一步的幫助，請隨時聯繫我們的客服團隊。感謝您的理解與支持！
      </h4>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="stylus">
#return-terms
  padding 50px 0

.terms__title
  font-size $font-size-title
  color #4A9A8A
  padding 10px 0

.terms__title-divider
  height 5px
  background #957200
  border-radius 4px
  width 100%

.terms__box
  padding 20px 80px 40px
  margin-top 80px
  background #D1E7E0

p
  font-size $font-size-lg3x
  text-indent -40px
  font-weight 500
  line-height 30px
  margin-top 20px
  padding-left 50px

h3
  font-size $font-size-lg3x
  font-weight 700
  margin-top 50px

h4
  font-size $font-size-lg3x
  font-weight 500
  margin-top 20px
  text-indent 40px
</style>
